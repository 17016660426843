var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isHistoryVisible)?_c('div',[(_vm.maps.length)?_c('div',{staticClass:"history"},[_c('p',{staticClass:"history-title"},[_vm._v(_vm._s(_vm.$t('matches.wf_map_few')))]),_vm._l((_vm.maps),function(map,key){return _c('div',{key:key,staticClass:"map-wrapper"},[_c('div',{staticClass:"maps"},[_c('div',{staticClass:"maps-count"},[_vm._v(" "+_vm._s(key + 1)+" "+_vm._s(_vm.$t('matches.wf_map_single'))+": ")]),_c('div',{staticClass:"maps-score"},[_c('span',{class:[
              'score',
              {
                win: map.side1 > map.side2,
                lose: map.side1 < map.side2,
              },
            ]},[_vm._v(" "+_vm._s(map.side1)+" ")]),_c('span',{staticClass:"divider"},[_vm._v(":")]),_c('span',{class:[
              'score',
              {
                win: map.side1 < map.side2,
                lose: map.side1 > map.side2,
              },
            ]},[_vm._v(" "+_vm._s(map.side2)+" ")])]),_c('div',{staticClass:"maps-title"},[_vm._v(_vm._s(map.title))])]),(_vm.isCsGame)?_c('div',{staticClass:"demo"},[_c('Button',{attrs:{"tag":"a","download":"","size":"small","type":"secondary","loading":false,"disabled":map.demoButton.status !== 'done',"text":"Скачать демо","href":map.demoButton.url}})],1):_vm._e()])})],2):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }