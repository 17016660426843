export const generatePassword = (length = 15) => {
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!?#+-_';
  const charsetLength = charset.length;
  let result = '';
  for (let i = length; i >= 0; i -= 1) {
    result += charset.charAt(
      Math.floor(Math.random() * charsetLength),
    );
  }
  return result;
};
