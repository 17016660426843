<template>
  <div class="section">
    <span v-if="title" class="section-title">{{ title }}</span>
    <div class="pvp-table stripped">
      <div class="table-header">
        <div
          class="table-row"
          :class="[classes.tableRow, playerNames.tableRow]"
        >
          <div class="table-column">№</div>
          <div class="table-column">{{ $t('teams.single') }}</div>
          <div v-if="!bracketShowAsTable" class="table-column">
            {{ $t('players.few') }}
          </div>
          <div class="table-column">{{ $t('places.single') }}</div>
          <div v-if="!disableKillPoints" class="table-column">
            {{ $t('scores.for_kills') }}
          </div>
          <div v-if="!disableRoundPoints" class="table-column">
            {{ $t('scores.for_win') }}
          </div>
          <div class="table-column">{{ $t('scores.single') }}</div>
        </div>
      </div>
      <div class="table-body">
        <div
          v-for="(team, key) in teams"
          :key="key"
          class="table-row"
          :class="[classes.tableRow, playerNames.tableRow]"
        >
          <div class="table-column">
            <div class="column-title">№</div>
            <div class="column-content">{{ team.slot }}</div>
          </div>
          <div class="table-column dotted">
            <div class="column-title">{{ $t('teams.single') }}</div>
            <BaseTeamLink class="column-content" :hash="team.hash" />
          </div>
          <div v-if="!bracketShowAsTable" class="table-column dotted">
            <div class="column-title">{{ $t('players.few') }}</div>
            <div class="column-content players">
              <BaseUserLink
                v-for="user in team.players"
                :key="user.hash"
                image-size="32"
                :hash="user.hash"
              >
                <template slot="username">{{
                  user.gameNick
                }}</template>
              </BaseUserLink>
            </div>
          </div>
          <div class="table-column">
            <div class="column-title">{{ $t('places.single') }}</div>
            <div class="column-content">{{ team.place }}</div>
          </div>
          <div v-if="!disableKillPoints" class="table-column">
            <div class="column-title">
              {{ $t('scores.for_kills') }}
            </div>
            <div class="column-content">
              {{ team.scoresDetail.killScores }}
            </div>
          </div>
          <div v-if="!disableRoundPoints" class="table-column">
            <div class="column-title">{{ $t('scores.for_win') }}</div>
            <div class="column-content">
              {{ team.scoresDetail.placeScores }}
            </div>
          </div>
          <div class="table-column">
            <div class="column-title">{{ $t('scores.single') }}</div>
            <div class="column-content">
              {{ team.isDisqualified ? '&mdash;' : team.scores }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';
import BaseUserLink from '@components/BaseComponents/BaseUserLink.vue';

export default {
  name: 'ParticipantsTable',
  components: {
    BaseTeamLink,
    BaseUserLink,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    teams: {
      type: Array,
      required: true,
    },
    disableKillPoints: {
      type: Boolean,
      default: false,
    },
    disableRoundPoints: {
      type: Boolean,
      default: false,
    },
    bracketShowAsTable: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    table() {
      const { teams } = this;
      return {
        hasSlots: teams[0].slot !== undefined,
        hasPlayers: teams[0].players !== undefined,
        teams,
      };
    },
    classes() {
      return {
        tableRow: this.disableKillPoints
          ? 'table-row--without-killpoints'
          : 'table-row--full-table',
      };
    },
    playerNames() {
      return {
        tableRow: this.bracketShowAsTable
          ? 'table-row--without-players'
          : 'table-row--full-table',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.pvp-table {
  @include max-tablet() {
    padding: 0 12px;
  }
}

.table-header {
  @include max-laptop() {
    display: none;
  }
}

.table-row {
  @include min-laptop() {
    display: grid;
  }

  &--full-table {
    @include min-laptop() {
      grid-template-columns: 1fr 2fr 2fr 1fr 1.5fr 1.5fr 0.5fr;
    }
  }

  &--without-players {
    @include min-laptop() {
      grid-template-columns: 1fr 2fr 2fr 1fr 1.5fr 1.5fr;
    }
  }

  &--without-killpoints {
    @include min-laptop() {
      grid-template-columns: 1fr 2fr 2fr 1fr 1.5fr 1.5fr;
    }
  }
}

.stripped .table-row .table-column {
  &,
  &:last-child,
  &:first-child {
    display: flex;
    @include max-laptop() {
      padding: 8px 12px;
    }
  }
}

.column {
  &-title {
    @include min-laptop() {
      display: none;
    }
    @include max-laptop() {
      font-size: 14px;
      width: 140px;
      color: rgba(white, 0.6);
    }
  }
}

.dotted {
  max-width: 100%;
  overflow: hidden;
  padding-right: 1em;
}

.players {
  max-width: 100%;

  .user-link {
    display: flex;

    & + .user-link {
      margin-top: 12px;
    }
  }
}
</style>
