<template>
  <div class="match-details-information">
    <h2 v-if="title" class="match-details-information__title">
      {{ title }}
    </h2>
    <ul class="match-details-information__list">
      <h3 class="match-details-information__list-title">
        {{ subtitle }}
      </h3>
      <li
        v-for="(game, idx) in games"
        :key="idx"
        class="match-details-information__list-item"
      >
        <p class="match-details-information__game">
          <span class="match-details-information__name">
            {{ game.name }}
          </span>
          <span class="match-details-information__total">
            <span
              class="match-details-information__score"
              :class="[
                getResultModifyClass(game[aTeamKey], game[bTeamKey]),
              ]"
            >
              {{ game[aTeamKey] }}
            </span>
            <span class="match-details-information__separator">
              :
            </span>
            <span
              class="match-details-information__score"
              :class="[
                getResultModifyClass(game[bTeamKey], game[aTeamKey]),
              ]"
            >
              {{ game[bTeamKey] }}
            </span>
          </span>
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MatchDetailsInformation',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    games: {
      type: Array,
      default: () => [],
      required: true,
    },
    aTeamKey: {
      type: String,
      default: '',
      required: true,
    },
    bTeamKey: {
      type: String,
      default: '',
      required: true,
    },
  },
  methods: {
    getResultModifyClass(pointA, pointB) {
      if (pointA === pointB) {
        return 'match-details-information__score--drawn';
      }
      if (pointA > pointB) {
        return 'match-details-information__score--win';
      }

      return 'match-details-information__score--lose';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.match-details-information {
  padding: 20px 6px;
  background: #232632;

  @include min-laptop() {
    padding: 20px 16px;
  }
}

.match-details-information__title {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding-bottom: 16px;
  border-bottom: 1px solid #2d303e;

  @include min-laptop() {
    text-align: unset;
    padding-bottom: 20px;
  }
}

.match-details-information__list {
  padding: 20px 0 0;

  @include min-laptop() {
    margin: 0 auto;
    padding: 30px 60px;
  }
}

.match-details-information__list-title {
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #f5f5f7;

  @include min-laptop() {
    font-size: 22px;
    line-height: 28px;
  }
}

.match-details-information__list-item {
  list-style: none;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.match-details-information__game {
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  text-align: center;

  @include min-laptop() {
    flex-wrap: nowrap;
    column-gap: 20px;
    text-align: left;
  }
}

.match-details-information__name {
  width: 100%;
  color: #979797;
  margin-bottom: 10px;

  @include min-laptop() {
    margin-bottom: 0;
  }
}

.match-details-information__total {
  width: 100%;
}

.match-details-information__score {
  &--win {
    color: #37c215;
  }
  &--lose {
    color: #ff3030;
  }
  &--drawn {
    color: #ffffff4d;
  }
}

.match-details-information__separator {
  color: #606169;
}
</style>
