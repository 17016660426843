<template>
  <div class="cancel">
    <div class="cancel-text">
      {{ $t('matches.server_cancel_description') }}
    </div>
    <pvp-btn
      :is-loading="loadingState.cancel"
      variant="secondary"
      @click="cancelServer"
    >
      {{ $t('matches.server_cancel_button') }}
    </pvp-btn>
  </div>
</template>

<script>
export default {
  name: 'ManualCancelServer',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loadingState: {
      cancel: false,
    },
  }),
  methods: {
    cancelServer() {
      this.loadingState.cancel = true;
      api
        .get(`/match/${this.id}/judge/servers/cancel`)
        .then(() => {
          this.$emit('success');
        })
        .catch(() => {})
        .finally(() => {
          this.loadingState.cancel = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel {
  &-text {
    font-size: 14px;
    line-height: 1.5;
    color: rgba(white, 0.43);
  }
}

.button {
  margin-top: 18px;
}
</style>
