<template>
  <div v-if="isHistoryVisible">
    <div v-if="progress.total > 0" class="history">
      <p class="history-title">
        {{ $t('matches.history_series_title') }}
        <span class="history-count">{{ progress.total }}</span>
      </p>
      <div class="history-rate">
        <div class="rate" :class="progress.bar.ltr ? 'win' : 'lose'">
          <div class="rate-title">
            {{ $t('matches.results_wins') }}
          </div>
          <div class="rate-count">
            {{ progress.team1.count }} ({{ progress.team1.percent }}%)
          </div>
        </div>
        <div class="rate" :class="progress.bar.ltr ? 'lose' : 'win'">
          <div class="rate-title">
            {{ $t('matches.results_wins') }}
          </div>
          <div class="rate-count">
            {{ progress.team2.count }} ({{ progress.team2.percent }}%)
          </div>
        </div>
      </div>
      <div class="history-bar">
        <div
          class="history-progress"
          :class="{
            full: progress.bar.width === 100,
            rtl: !progress.bar.ltr,
          }"
          :style="{
            width: `${progress.bar.width}%`,
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SeriesHistoryStats',

  props: {
    match: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('tournaments', ['getTournament']),
    ...mapGetters('application', ['getGameCode']),

    gameCode() {
      return this.getGameCode(this.tournament?.idGame);
    },
    matchDemos() {
      return this.match?.matchDemos.items.reduce((acc, demo) => {
        acc[demo.map] = demo;
        return acc;
      }, {});
    },
    gameId() {
      const tournament = this.getTournament(this.match?.idTournament);
      return tournament?.idGame;
    },
    isCsGame() {
      const csgoGameId = 24;
      const cs2Code = 'cs2';
      return this.gameId === csgoGameId || this.gameCode === cs2Code;
    },
    maps() {
      const maps = {};
      this.match.statistics.games.forEach(({ side, games }) => {
        games.forEach((game) => {
          maps[game.id] = {
            ...maps[game.id],
            [`side${side}`]: game.score,
            title: game.map,
          };
        });
      });
      if (this.isCsGame) {
        return Object.values(maps).map((obj) => {
          const demoRecord = this.matchDemos[obj.title];
          if (demoRecord && demoRecord.status === 'done') {
            return { demoButton: demoRecord, ...obj };
          } else {
            return { demoButton: { status: 'disabled' }, ...obj };
          }
        });
      } else {
        return Object.values(maps);
      }
    },
    stat() {
      const stat = this.match.statistics;
      return {
        isExist: stat.between !== undefined,
        ...stat.between,
      };
    },
    progress() {
      if (this.stat.isExist) {
        const team = this.match.teams.find((team) => team.side === 1);
        let winCount = this.match.hashWinner === team.hash ? 1 : 0;
        this.stat.matches.forEach((match) => {
          const teamOne = [match.team1, match.team2].find(
            (item) => item.hash === team.hash,
          );
          if (teamOne.id === match.idWinner) {
            winCount += 1;
          }
        });
        const total =
          this.stat.matches.length +
          (this.match.hashWinner === null ? 0 : 1);
        const winPercent = Math.trunc((winCount / total) * 100);

        const team1 = {
          count: winCount,
          percent: winPercent,
        };

        const team2 = {
          count: total - winCount,
          percent: 100 - winPercent,
        };

        return {
          team1,
          team2,
          total,
          bar: {
            ltr: team1.count >= team2.count,
            width:
              team1.percent > team2.percent
                ? team1.percent
                : team2.percent,
          },
        };
      }
      return {
        total: 0,
      };
    },
    isHistoryVisible() {
      return (
        this.progress.total > 0 ||
        (this.match &&
          this.match.statistics &&
          _.isArray(this.match.statistics.games))
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.history {
  position: relative;
  background: rgba(black, 0.2);
  margin-top: 1px;
  @include min-tablet() {
    padding: 24px 30px;
  }
  @include max-tablet() {
    padding: 20px 12px;
  }

  &-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  &-count {
    color: rgba(white, 0.6);
  }

  .win {
    color: $dark-pastel-green;
  }

  .lose {
    color: $orangey-red;
  }

  &-rate {
    display: flex;
    justify-content: space-between;
  }

  &-bar {
    margin-top: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: $orangey-red;
    position: relative;
  }

  &-progress {
    border-radius: 3px;
    height: 100%;
    background: $dark-pastel-green;
    display: flex;

    &:after {
      content: '';
      display: none;
      position: absolute;
      top: 0;
      width: 6px;
      height: 6px;
      background: $orangey-red;
      border-radius: 50%;
    }

    &.rtl {
      margin-left: auto;

      &.full:after {
        left: -6px;
      }
    }

    &.full {
      &:after {
        display: block;
        left: 100%;
      }
    }
  }

  .rate {
    &-title {
      color: rgba(white, 0.5);
    }
  }
}
</style>
