<template>
  <pvp-modal v-model="modalOpened" width="580">
    <div slot="modal-title">{{ $t('matches.results_add') }}</div>

    <div class="match-edit-title">{{ $t('screenshots.upload') }}</div>

    <files-uploader
      v-model="files"
      :multiple="true"
      :max-file-size="5"
    >
      <template slot="helper-text">
        {{ $t('uploader.maxFileCounts', { count: 10 }) }}
      </template>
    </files-uploader>

    <InfoBox v-if="hasErrors" variant="error">
      {{ $t('save.error') }}
    </InfoBox>

    <template v-if="success">
      <InfoBox variant="success">
        {{ $t('matches.results_uploaded') }}
      </InfoBox>
      <template slot="modal-footer">
        <pvp-btn variant="secondary" @click="modalOpened = false">{{
          $t('global.close')
        }}</pvp-btn>
      </template>
    </template>
    <pvp-btn
      v-else
      slot="modal-footer"
      :is-loading="loadingState.update"
      @click="saveResult"
    >
      {{ $t('save.single') }}
    </pvp-btn>
  </pvp-modal>
</template>

<script>
import FilesUploader from '@components/BaseComponents/FilesUploader.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'ScreenshotsUploadModal',
  components: { FilesUploader, InfoBox },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    matchId: {
      type: [String, Number],
      required: true,
    },
    gameId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      files: [],
      modalOpened: false,

      loadingState: {
        ...this.loadingState,
        update: false,
      },

      imgScreens: [],

      errors: {},
      success: false,
    };
  },
  computed: {
    hasErrors() {
      return (
        Object.values(this.errors).filter((value) => value).length > 0
      );
    },
  },
  watch: {
    modalOpened(value) {
      this.$emit('input', value);
    },
    value: {
      handler(value) {
        this.modalOpened = value;
      },
      immediate: true,
    },
  },
  methods: {
    uploadFiles(file) {
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('type', 'matchGameResult');
      formData.append('matchId', this.matchId);

      file.loading = true;
      return api
        .post('/file/uploadandresize', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress(progressEvent) {
            const percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            file.loadedPercent = percent;
          },
        })
        .then((data) => {
          this.imgScreens.push(data.id);
          file.success = true;
          return true;
        })
        .catch(() => {
          file.error = this.$t('save.error');
          return false;
        })
        .finally(() => {
          file.loading = false;
        });
    },

    saveResult() {
      Promise.all(this.files?.map(this.uploadFiles) || []).then(
        () => {
          this.success = false;
          this.errors = {};
          this.loadingState.update = true;

          return api
            .post(
              `/match/${this.matchId}/player/result/${this.gameId}/save`,
              {
                imgScreens: {
                  add: this.imgScreens,
                },
              },
            )
            .then(() => {
              this.success = true;
              this.$emit('uploaded');
            })
            .catch(({ error = [], code }) => {
              if (code) {
                this.errors = { serverError: true };
              } else {
                this.errors = _.reduce(
                  error,
                  (acc, error, field) => ({
                    ...acc,
                    [field]: error,
                  }),
                  {},
                );
              }
            })
            .finally(() => {
              this.loadingState.update = false;
            });
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.match-edit-title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
}
</style>
