<template>
  <div v-if="info.server_password" class="server">
    <p class="server-title">
      {{ $t('matches.server_info_title') }}
    </p>
    <div class="box">
      <span class="box-title">
        {{ $t('matches.server_info_name') }}
      </span>
      <span class="box-text">{{ info.server_name }}</span>
    </div>
    <div class="box">
      <span class="box-title">
        {{ $t('global.password') }}
      </span>
      <span class="box-text">{{ info.server_password }}</span>
    </div>
    <div class="box">
      <span class="box-title">
        {{ $t('players.region_label') }}
      </span>
      <span class="box-text">{{ info.server_region }}</span>
    </div>
    <template
      v-for="(item, key) in instructions"
      class="instructions"
    >
      <a :key="key" :href="item.url" target="_blank">{{
        item.name
      }}</a
      ><br :key="key" />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('application', ['getGame']),

    instructions() {
      const game = this.info.idGame && this.getGame(this.info.idGame);
      return game ? game.instructions : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.server {
  background-color: rgba(black, 0.21);
  padding: 30px 0;

  &-title {
    font-size: 18px;
    font-weight: bold;
  }
}

.box {
  font-size: 13px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 'title text';
  gap: 16px;
  margin-top: 5px;

  &-title {
    grid-area: title;
    color: rgba(white, 0.35);
    text-align: right;
  }

  &-text {
    grid-area: text;
    text-align: left;
    word-wrap: break-word;
    overflow: hidden;
    padding-right: 20px;
  }
}
</style>
