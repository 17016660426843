<template>
  <box class="files">
    <div class="files-wrapper">
      <files-uploader
        :value="value"
        :multiple="true"
        @input="setFiles"
      />
      <pvp-btn
        v-for="(img, key) in uploadedList"
        :key="key"
        class="gallery-item"
        variant="clear"
        @click="showImage(img.id)"
      >
        {{ $t('screenshots.open', { n: `#${key + 1}` }) }}
      </pvp-btn>
    </div>
  </box>
</template>

<script>
import FilesUploader from '@components/BaseComponents/FilesUploader.vue';

export default {
  name: 'JudgeScreenshotUploader',
  components: { FilesUploader },
  props: {
    value: {
      type: Array,
      required: true,
    },
    matchId: {
      type: Number,
      required: true,
    },
    gameId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    uploadedList: [],
  }),
  watch: {
    gameId: {
      handler: 'uploadImages',
      immediate: true,
    },
  },
  methods: {
    uploadImages() {
      api
        .get(`/match/${this.matchId}/judge/result/${this.gameId}`)
        .then((result) => {
          this.uploadedList = result.imgScreens;
        });
    },
    setFiles(files) {
      this.$emit('input', files);
    },
    showImage(id) {
      this.$emit('showImage', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.files {
  padding-bottom: 30px;

  &-wrapper {
    margin: 0 auto;
    max-width: 660px;
  }
}

.gallery-item {
  &,
  &:focus {
    display: block;
    color: $azure;
    margin: 12px auto 0;
  }
}
</style>
