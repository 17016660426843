<template>
  <div v-if="isHistoryVisible">
    <div v-if="maps.length" class="history">
      <p class="history-title">{{ $t('matches.wf_map_few') }}</p>
      <div v-for="(map, key) in maps" :key="key" class="map-wrapper">
        <div class="maps">
          <div class="maps-count">
            {{ key + 1 }} {{ $t('matches.wf_map_single') }}:
          </div>
          <div class="maps-score">
            <span
              :class="[
                'score',
                {
                  win: map.side1 > map.side2,
                  lose: map.side1 < map.side2,
                },
              ]"
            >
              {{ map.side1 }}
            </span>
            <span class="divider">:</span>
            <span
              :class="[
                'score',
                {
                  win: map.side1 < map.side2,
                  lose: map.side1 > map.side2,
                },
              ]"
            >
              {{ map.side2 }}
            </span>
          </div>
          <div class="maps-title">{{ map.title }}</div>
        </div>
        <div v-if="isCsGame" class="demo">
          <Button
            tag="a"
            download
            size="small"
            type="secondary"
            :loading="false"
            :disabled="map.demoButton.status !== 'done'"
            text="Скачать демо"
            :href="map.demoButton.url"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';

export default {
  name: 'SeriesHistoryMaps',

  components: { Button },

  props: {
    match: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('tournaments', ['getTournament']),
    ...mapGetters('application', ['getGameCode']),

    matchDemos() {
      return this.match?.matchDemos.items.reduce((acc, demo) => {
        acc[demo.map] = demo;
        return acc;
      }, {});
    },
    tournament() {
      return this.getTournament(this.match?.idTournament);
    },
    gameId() {
      return this.tournament?.idGame;
    },
    gameCode() {
      return this.getGameCode(this.tournament?.idGame);
    },
    isCsGame() {
      const csgoGameId = 24;
      const cs2Code = 'cs2';
      return this.gameId === csgoGameId || this.gameCode === cs2Code;
    },
    maps() {
      const maps = {};
      this.match.statistics.games.forEach(({ side, games }) => {
        games.forEach((game) => {
          maps[game.id] = {
            ...maps[game.id],
            [`side${side}`]: game.score,
            title: game.map,
          };
        });
      });
      if (this.isCsGame) {
        return Object.values(maps).map((obj) => {
          const demoRecord = this.matchDemos[obj.title];
          if (demoRecord && demoRecord.status === 'done') {
            return { demoButton: demoRecord, ...obj };
          } else {
            return { demoButton: { status: 'disabled' }, ...obj };
          }
        });
      } else {
        return Object.values(maps);
      }
    },
    stat() {
      const stat = this.match.statistics;
      return {
        isExist: stat.between !== undefined,
        ...stat.between,
      };
    },
    progress() {
      if (this.stat.isExist) {
        const team = this.match.teams.find((team) => team.side === 1);
        let winCount = this.match.hashWinner === team.hash ? 1 : 0;
        this.stat.matches.forEach((match) => {
          const teamOne = [match.team1, match.team2].find(
            (item) => item.hash === team.hash,
          );
          if (teamOne.id === match.idWinner) {
            winCount += 1;
          }
        });
        const total =
          this.stat.matches.length +
          (this.match.hashWinner === null ? 0 : 1);
        const winPercent = Math.trunc((winCount / total) * 100);

        const team1 = {
          count: winCount,
          percent: winPercent,
        };

        const team2 = {
          count: total - winCount,
          percent: 100 - winPercent,
        };

        return {
          team1,
          team2,
          total,
          bar: {
            ltr: team1.count >= team2.count,
            width:
              team1.percent > team2.percent
                ? team1.percent
                : team2.percent,
          },
        };
      }
      return {
        total: 0,
      };
    },
    isHistoryVisible() {
      return (
        this.progress.total > 0 ||
        (this.match &&
          this.match.statistics &&
          _.isArray(this.match.statistics.games))
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.history {
  position: relative;
  background: rgba(black, 0.2);
  margin-top: 1px;
  @include min-tablet() {
    padding: 24px 30px;
  }
  @include max-tablet() {
    padding: 20px 12px;
  }

  &-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .win {
    color: $dark-pastel-green;
  }

  .lose {
    color: $orangey-red;
  }
}

.map-wrapper {
  margin-bottom: 16px;
}

.maps {
  display: flex;
  align-items: baseline;
  margin-bottom: 4px;

  &-score {
    width: 85px;
    font-size: 17px;
    font-weight: bold;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    color: rgba(white, 0.5);

    .score {
      flex-basis: 0;
      flex-grow: 1;
      align-self: center;
    }

    .divider {
      color: rgba(white, 0.2);
      flex-shrink: 0;
    }
  }

  &-count {
    text-align: right;
    color: rgba(white, 0.5);
    padding-right: 4px;
  }

  &-title {
    text-align: left;
    padding-left: 4px;
  }

  &-title,
  &-count {
    flex-grow: 1;
    flex-basis: 0;
    font-size: 14px;
  }
}
</style>
