<template>
  <loader v-if="loadingState.checkin" />

  <div v-else-if="timerBeforeCheckin" class="info-box">
    <span class="info-title"
      >{{ $t('matches.checkin_beforeStart_title') }}:</span
    >
    <time-left
      :to="timerBeforeCheckin"
      :is-server-time="true"
      :format="timerBeforeCheckin | filterFormat"
      @timeout="onBeforeCheckinTimeout"
    />
    <span class="info-title">
      <template v-if="isCaptain">
        {{ $t('matches.checkin_beforeStart_confirm') }}
      </template>
      <template v-else>
        {{ $t('matches.checkin_beforeStart_captainConfirm') }}
      </template>
    </span>
  </div>

  <div v-else-if="checkin">
    <p
      v-if="isTimeoutExpired && !isCheckedBy.bothTeams"
      class="info-title"
    >
      {{ $t('matches.checkin_inprogress_wait') }}
    </p>
    <template v-else-if="!isCheckedBy.bothTeams">
      <div class="info-box">
        <p v-if="isCheckedBy.currentTeam" class="info-title">
          {{ $t('matches.checkin_inprogress_waitOpponent') }}
        </p>
        <template v-else>
          <p class="info-title">
            {{ $t('matches.checkin_inprogress_needConfirm') }}
            <template
              v-if="
                userStatus.abilities.canCheckIn &&
                tournament.isPickBanSystem
              "
            >
              {{ $t('matches.checkin_inprogress_pickbanInfo') }}
            </template>
          </p>
          <pvp-btn @click="fetchConfirm">{{
            $t('global.confirm')
          }}</pvp-btn>
        </template>

        <template v-if="checkin.tsStartTime">
          <time-left
            format="mm:ss"
            :to="checkin.tsStartTime + checkin.timeout"
            :is-server-time="true"
            @timeout="onTimeout"
          />
          <p class="time-left-text">
            {{
              $t(
                `matches.tech_to_${
                  isCheckedBy.currentTeam ? 'win' : 'defeat'
                }`,
              )
            }}
          </p>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { formatDuration } from '@utils/dates';

export default {
  name: 'Checkin',
  filters: {
    filterFormat(ts) {
      const { days } = formatDuration(
        +(Date.now() / 1000).toFixed(0),
        ts,
      );
      return days ? 'dd:hh:mm:ss' : 'hh:mm:ss';
    },
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loadingState: {
      checkin: false,
    },
    currentTime: +(Date.now() / 1000).toFixed(0),
    isTimeoutExpired: false,
  }),
  computed: {
    ...mapGetters('matches', ['getMatchCheckin', 'getMatch']),
    ...mapGetters('users', ['getUserMatchStatus']),
    ...mapGetters('tournaments', ['getTournament']),
    match() {
      return this.getMatch(this.id);
    },
    checkin() {
      return this.getMatchCheckin(this.id);
    },
    tournament() {
      return this.getTournament(this.match.idTournament);
    },
    userStatus() {
      return this.getUserMatchStatus(this.id);
    },
    timerBeforeCheckin() {
      const startTime = _.get(this.match, 'tsStartTime', 0);
      return startTime > this.currentTime ? startTime : 0;
    },
    isCaptain() {
      return this.userStatus.roles.captain;
    },
    isCheckedBy() {
      const { canCheckIn } = this.userStatus.abilities;

      const result = {
        oneOfTeams:
          this.checkin.status === 1 || this.checkin.status === 2,
        bothTeams: this.checkin.status === 3,
        currentTeam: this.checkin.status === 3,
      };

      if (result.oneOfTeams) {
        result.currentTeam = !canCheckIn;
      }
      return result;
    },
  },
  watch: {
    'checkin.status': function () {
      this.$nextTick(() => {
        this.loadingState.checkin = false;
      });
    },
    timerBeforeCheckin: async function () {
      await this.fetchCheckinStatus();
    },
  },
  created() {
    if (!this.timerBeforeCheckin) {
      this.fetchCheckinStatus();
    }
  },
  methods: {
    ...mapActions('matches', [
      'fetchMatchCheckinConfirm',
      'fetchMatchCheckinStatus',
    ]),
    async fetchConfirm() {
      this.loadingState.checkin = true;
      return await this.fetchMatchCheckinConfirm(this.id);
    },
    onTimeout() {
      this.isTimeoutExpired = true;
    },
    onBeforeCheckinTimeout() {
      this.loadingState.checkin = true;
      this.currentTime = this.currentTime = +(
        Date.now() / 1000
      ).toFixed(0);
    },
    fetchCheckinStatus() {
      if (!this.isCaptain) {
        return;
      }
      this.fetchMatchCheckinStatus(this.id).then(() => {
        this.loadingState.checkin = false;
        if (this.checkin?.tsStartTime) {
          this.isTimeoutExpired =
            this.checkin.tsStartTime + this.checkin.timeout <=
            Date.now() / 1000;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  margin: 20px auto;
}

.info {
  &-title {
    display: block;
    font-size: 18px;
    font-weight: bold;
    text-align: center;

    & + span {
      margin-top: 12px;
    }
  }

  &-box {
    margin-top: 20px;
    border-radius: 2px;
    background-color: rgba(black, 0.2);
    padding: 24px 30px;
  }
}

.time-left {
  display: block;
  text-align: center;
  font-size: 30px;
  color: $azure;
  margin-top: 20px;

  &-text {
    text-align: center;
    font-size: 14px;
    color: rgba(white, 0.4);
    text-transform: lowercase;
  }
}

.button {
  margin-top: 1em;
}
</style>
