<template>
  <BaseSelect
    v-model="selected"
    :options="getMatchProtests"
    :readonly="true"
    :status="error ? 'error' : ''"
    :label="label"
    :message="error"
    value-key="id"
    label-key="name"
    :placeholder="$t('matches.complain_select')"
  >
    <template v-slot:option="{ option }">
      <div :id="option.id">{{ option.name }}</div>
    </template>
    <template v-slot:selected-option="{ option }">
      <div :id="option.id">{{ option.name }}</div>
    </template>
  </BaseSelect>
</template>

<script>
import BaseSelect from '@components/BaseComponents/Form/Select.vue';

export default {
  name: 'MatchResultsSelect',
  components: {
    BaseSelect,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    selected: null,
  }),
  computed: {
    ...mapGetters('matches', ['getMatchProtests']),
  },
  watch: {
    selected(value) {
      this.$emit('input', Number(value));
    },

    value: {
      handler(value) {
        this.selected = value;
      },
      immediate: true,
    },
  },
};
</script>
