<template>
  <div v-if="user.gameProfileLink" class="game-profile-container">
    <pvp-tooltip v-if="appIsLarge" class="game-profile-tooltip">
      <a
        class="game-profile-link"
        target="_blank"
        :title="gameProfileLinkTitle"
        :href="user.gameProfileLink"
      >
        <Icon name="steam" />
      </a>
      <template slot="tooltip">{{ gameProfileLinkTitle }}</template>
    </pvp-tooltip>
    <a
      v-else
      class="game-profile-link"
      target="_blank"
      :title="gameProfileLinkTitle"
      :href="user.gameProfileLink"
    >
      <Icon name="steam" />
    </a>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'GameProfileLink',
  components: {
    Icon,
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('application', ['appIsLarge']),

    gameProfileLinkTitle() {
      const service =
        {
          steam: 'Steam',
          battlenet: 'BattleNet',
        }[this.game.linkType] || this.$t('players.linkTo_service');

      return this.$t('players.linkTo_text', {
        service,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.game-profile-container {
  position: absolute;
  top: 40px;
  right: auto;
  bottom: auto;
  left: 45px;
  $size: 28px;
}

.game-profile-link {
  $size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(white, 0.6);
  height: $size;
  width: $size;
  font-size: $size - 6px;
  border-radius: 100%;
  background-color: $dark-two;
  cursor: pointer;

  &:hover {
    color: rgba(white, 0.8);
  }
}
</style>
