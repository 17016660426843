<template>
  <pvp-modal
    :value="$attrs.value"
    width="580"
    @input="$listeners.input"
  >
    <div v-if="isMainError" class="edit-main-error">
      {{ $t('matches.results_errors_add') }}
    </div>
    <template v-else>
      <div slot="modal-title">
        {{ $t('matches.results_add') }}
      </div>

      <form class="match-edit-form">
        <div v-if="!rentServer" class="teams-places-row">
          <div
            class="team-name-label team-one"
            :class="{ active: teamOne.captain }"
          >
            {{ teamOne.name }}
          </div>
          <BaseInput
            v-model.number="pointsTeamOne"
            class="teams-point-input"
            type="number"
          />
          <div class="delimiter-dots">:</div>
          <BaseInput
            v-model.number="pointsTeamTwo"
            class="teams-point-input"
            type="number"
          />
          <div
            class="team-name-label team-two"
            :class="{ active: teamTwo.captain }"
          >
            {{ teamTwo.name }}
          </div>
        </div>

        <div v-if="errors.pointsTeam1" class="error">
          {{ teamOne.name }}: {{ errors.pointsTeam1 }}
        </div>
        <div v-if="errors.pointsTeam2" class="error">
          {{ teamTwo.name }}: {{ errors.pointsTeam2 }}
        </div>

        <BaseCheckbox
          v-if="canAddProtest"
          v-model="showSpecialsBlock"
          @input="skipSpecial"
        >
          {{ $t('matches.complain_title') }}
        </BaseCheckbox>

        <transition name="grow">
          <div v-if="showSpecialsBlock" class="match-edit-specials">
            <match-results-select
              v-model="statusId"
              :label="$t('matches.complain_reason')"
              :error="errors.specialStatus"
            />

            <BaseInput
              v-model="complaint"
              :is-area="true"
              :label="$t('matches.complain_problem')"
              :status="errors.complaint ? 'error' : ''"
              :maxlength="1000"
              row="10"
              :message="errors.complaint"
            />
          </div>
        </transition>

        <div class="match-edit-title">
          {{ $t('uploader.screenshots') }}
        </div>
        <files-uploader
          v-model="files"
          :multiple="true"
          :max-file-size="5"
        />
      </form>
      <template slot="modal-footer">
        <pvp-btn
          class="match-edit-button"
          variant="primary"
          :disabled="loadingState.update"
          :is-loading="loadingState.update"
          @click="saveResult"
          >{{ $t('save.single') }}
        </pvp-btn>
      </template>
    </template>
  </pvp-modal>
</template>

<script>
import MatchResultsSelect from '@components/Match/Common/MatchResultsSelect.vue';
import FilesUploader from '@components/BaseComponents/FilesUploader.vue';
import BaseCheckbox from '@components/BaseComponents/Form/BaseCheckbox.vue';
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';

export default {
  name: 'EditMatchModal',
  components: {
    FilesUploader,
    MatchResultsSelect,
    BaseCheckbox,
    BaseInput,
  },
  props: {
    teamOneHash: {
      type: String,
      required: true,
    },
    teamTwoHash: {
      type: String,
      required: true,
    },
    matchId: {
      type: Number,
      required: true,
    },
    rentServer: {
      type: Boolean,
      required: true,
    },
    isCaptain: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loadingState: {
        ...this.loadingState,
        update: false,
      },

      showSpecialsBlock: false,

      pointsTeamOne: null,
      pointsTeamTwo: null,
      complaint: null,
      files: [],
      imgScreens: [],
      statusId: null,

      errors: {},
      success: false,
      isMainError: false,
    };
  },
  computed: {
    ...mapGetters('users', ['getUserMatchStatus']),
    ...mapGetters('matches', ['getMatchProtest']),
    ...mapGetters('teams', ['getTeam']),
    ...mapGetters('profile', ['getCurrentProfileHash']),

    teamOne() {
      const team = this.getTeam(this.teamOneHash);
      return {
        ...team,
        captain:
          team?.players?.find((player) => player.isCaptain)?.hash ===
          this.userHash,
      };
    },

    teamTwo() {
      const team = this.getTeam(this.teamTwoHash);
      return {
        ...team,
        captain:
          team?.players?.find((player) => player.isCaptain)?.hash ===
          this.userHash,
      };
    },

    userHash() {
      return this.getCurrentProfileHash;
    },

    canAddProtest() {
      return this.getUserMatchStatus(this.matchId)?.abilities
        ?.canAddProtest;
    },
  },

  methods: {
    ...mapActions('matches', ['matchCommonResult']),

    skipSpecial() {
      if (this.showSpecialsBlock === false) {
        this.complaint = null;
        this.statusId = null;
      }
    },

    uploadFiles(file) {
      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('type', 'matchResult');
      formData.append('id', this.matchId);

      file.loading = true;
      return api
        .post('/file/uploadandresize', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress(progressEvent) {
            const percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            file.loadedPercent = percent;
          },
        })
        .then((data) => {
          this.imgScreens.push(data.id);
          file.success = true;
          return true;
        })
        .catch(() => {
          file.error = this.$t('save.error');
          return false;
        })
        .finally(() => {
          file.loading = false;
        });
    },

    async saveResult() {
      this.success = false;
      this.errors = {};
      this.loadingState.update = true;

      if (this.showSpecialsBlock && this.canAddProtest) {
        api.post(`/match/${this.matchId}/protest/create`, {
          form: {
            name:
              this.getMatchProtest(this.statusId)?.name ||
              'не указано',
            comment: this.complaint,
          },
        });
      }

      Promise.all(this.files?.map(this.uploadFiles) || []).then(
        () => {
          const teamHash = this.teamOne.captain
            ? this.teamOne.hash
            : this.teamTwo.hash;

          this.matchCommonResult({
            teamHash,
            id: this.matchId,
            specialStatus: this.statusId,
            complaint: this.complaint,
            pointsTeamOne: this.pointsTeamOne,
            pointsTeamTwo: this.pointsTeamTwo,
            imgScreens: this.imgScreens,
          })
            .then(() => {
              this.success = true;
              this.$emit('input', false);
            })
            .catch(({ error = [] }) => {
              this.errors = _.reduce(
                error,
                (acc, err, field) => ({
                  ...acc,
                  [field]: err.message,
                }),
                {},
              );
              if (error.code === 403) {
                this.isMainError = true;
              }
            })
            .finally(() => {
              this.loadingState.update = false;
            });
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.match-edit-form {
  padding: 0 66px;
  @include max-tablet() {
    padding: 0;
  }
}

.edit-main-error {
  text-align: center;
}

.teams-places-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.team-points-form {
  display: flex;
  position: relative;
  align-items: center;

  &.team-one {
    justify-content: flex-end;
  }
}

.teams-point-input {
  width: 64px;
  margin: 0 6px;
}

.team-name-label {
  width: calc(50% - 64px);
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.active {
    color: $azure;
  }

  &.team-one {
    margin-right: 12px;
    text-align: right;
  }

  &.team-two {
    margin-left: 12px;
  }
}

.delimiter-dots {
  opacity: 0.4;
}

.match-edit-checkbox {
  margin-top: 10px;
}

.match-edit-title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
}

.match-edit-textarea {
  height: 73px;
  resize: none;
}

.match-edit-specials {
  margin-top: 8px;
}

.match-edit-button {
  width: 183px;
  @include max-tablet() {
    width: 100%;
  }
}

.error {
  margin-bottom: 4px;
  font-size: 12px;
  color: $orangey-red;
}

.grow {
  &-leave,
  &-enter {
    &-active {
      transition: max-height 0.2s linear;
      overflow: hidden;
    }
  }

  &-enter {
    max-height: 0;

    &-to {
      max-height: 500px;
    }
  }

  &-leave {
    max-height: 500px;

    &-to {
      max-height: 0;
    }
  }
}
</style>
