import { render, staticRenderFns } from "./SeriesHistoryTable.vue?vue&type=template&id=29eff63e&scoped=true"
import script from "./SeriesHistoryTable.vue?vue&type=script&lang=js"
export * from "./SeriesHistoryTable.vue?vue&type=script&lang=js"
import style0 from "./SeriesHistoryTable.vue?vue&type=style&index=0&id=29eff63e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29eff63e",
  null
  
)

export default component.exports