import { render, staticRenderFns } from "./Short.vue?vue&type=template&id=2cf0a3f7&scoped=true"
import script from "./Short.vue?vue&type=script&lang=js"
export * from "./Short.vue?vue&type=script&lang=js"
import style0 from "./Short.vue?vue&type=style&index=0&id=2cf0a3f7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cf0a3f7",
  null
  
)

export default component.exports