<template>
  <div v-if="matches.length" class="section">
    <span class="section-title"
      >{{ $t('matches.few') }} {{ team.name }}</span
    >
    <div class="pvp-table stripped">
      <div class="table-header">
        <div class="table-row">
          <div class="table-column">{{ $t('date.single') }}</div>
          <div class="table-column">{{ $t('global.result') }}</div>
          <div class="table-column">{{ $t('global.enemy') }}</div>
          <div class="table-column"></div>
        </div>
      </div>
      <div class="table-body">
        <div
          v-for="(match, key) in matches"
          :key="key"
          class="table-row"
        >
          <div class="table-column date">
            <div class="column-title">{{ $t('date.single') }}</div>
            <div class="column-content">
              {{ $dt(match.tsStartTime, 'ddd_dd_MMM_HH_mm') }}
            </div>
          </div>
          <div class="table-column result">
            <div class="column-title">{{ $t('global.result') }}</div>
            <div class="column-content">
              <span
                :class="team.hash | filterWinner(match.hashWinner)"
              >
                {{ match.points[team.hash] }}
              </span>
              <span class="divider">:</span>
              <span
                :class="
                  match.enemyHash | filterWinner(match.hashWinner)
                "
              >
                {{ match.points[match.enemyHash] }}
              </span>
            </div>
          </div>
          <div class="table-column">
            <div class="column-title">{{ $t('global.enemy') }}</div>
            <BaseTeamLink
              v-if="match.enemyHash !== freeSlot.hash"
              class="column-content"
              :hash="match.enemyHash"
            />
            <span v-else>&mdash;</span>
          </div>
          <div class="table-column">
            <BaseLink
              v-if="match.enemyHash !== freeSlot.hash"
              :to="{ name: 'match', params: { id: match.id } }"
              icon-right="arrow-right"
            >
              {{ $t('global.more') }}
            </BaseLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLink from '@components/BaseComponents/BaseLink.vue';
import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';

export default {
  components: {
    BaseLink,
    BaseTeamLink,
  },
  filters: {
    filterWinner(hash, winnerHash) {
      if (!winnerHash) {
        return '';
      }
      return winnerHash === hash ? 'win' : 'lose';
    },
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    hash: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('teams', ['getTeam']),
    ...mapGetters('matches', ['getMatch']),
    team() {
      return this.getTeam(this.hash);
    },

    matches() {
      const stats = this.getMatch(this.id).statistics;
      if (
        stats !== undefined &&
        stats.matches !== undefined &&
        stats.matches[this.hash] !== undefined
      ) {
        return stats.matches[this.hash].matches;
      }
      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
.pvp-table {
  @include max-tablet() {
    margin-left: -12px;
    margin-right: -12px;
  }
}

.stripped .table-row .table-column {
  &,
  &:last-child,
  &:first-child {
    display: flex;
    @include max-laptop() {
      padding: 8px 12px;
    }
  }
}

.table {
  &-header {
    @include max-laptop() {
      display: none;
    }
  }

  &-row {
    @include min-laptop() {
      display: grid;
      grid-template-columns: 30% 20% 20% 30%;
    }
  }

  &-column {
    padding: 0 5px;

    &.date .column-content:first-letter {
      text-transform: uppercase;
    }

    @include min-laptop() {
      &:last-child {
        justify-content: flex-end;
      }
    }
  }

  &-btn {
    margin-top: 20px;
  }
}

.result {
  .column-content {
    font-weight: bold;
    color: rgba(white, 0.4);
  }

  .win {
    color: $dark-pastel-green;
  }

  .divider {
    padding: 0 4px;
    color: rgba(white, 0.3);
  }

  .lose {
    color: $orangey-red;
  }
}

.column {
  &-title {
    color: rgba(white, 0.6);
    @include min-laptop() {
      display: none;
    }
    @include max-laptop() {
      width: 90px;
      flex-shrink: 0;
    }
  }
}
</style>
