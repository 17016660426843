<template>
  <p class="short">
    {{ $t('matches.shortPickban', { team: name }) }}
  </p>
</template>
<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('matches', ['getMatch']),
    ...mapGetters('teams', ['getTeam']),
    name() {
      const { teamPriority, teams } = this.getMatch(this.id);
      const { hash } = teams.find(
        (team) => team.side === teamPriority,
      );
      const { name } = this.getTeam(hash);
      return name;
    },
  },
};
</script>

<style lang="scss" scoped>
.short {
  margin: 1px 0 0;
  padding: 12px 0;
  font-weight: bold;
  background: rgba(black, 0.2);
}
</style>
