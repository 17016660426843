<template>
  <div v-if="changes.length" class="section">
    <span class="section-title">
      {{ $t('matches.history_changes_title') }}
    </span>
    <div class="pvp-table stripped">
      <div class="table-header">
        <div class="table-row">
          <div class="table-column">{{ $t('date.time') }}</div>
          <div class="table-column">{{ $t('global.action') }}</div>
        </div>
      </div>
      <div class="table-body">
        <div
          v-for="(action, key) in changes"
          :key="key"
          class="table-row"
        >
          <div class="table-column date">
            <div class="column-title">{{ $t('date.time') }}</div>
            <div class="column-content">
              {{ $dt(action.tsChangeTime, 'dd_MM_YYYY_HH_mm') }}
            </div>
          </div>
          <div class="table-column action">
            <div class="column-title">{{ $t('global.action') }}</div>
            <div class="column-content">
              <template v-if="action.action_name">
                <template v-if="action.nick">
                  {{ $t('teams.single') }}
                  <b>
                    {{ action.nick }}
                  </b>
                  <span class="lower">{{ action.action_name }}</span>
                </template>
                <span v-else>{{ action.action_name }}</span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pvp-pagination
      v-if="pagination.page"
      :params="pagination"
      @onchange="fetchMatchHistory"
    />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    changes: [],
    pagination: {
      page: 1,
      perPage: 12,
    },
  }),
  created() {
    this.fetchMatchHistory(this.pagination);
  },
  methods: {
    fetchMatchHistory({ page, perPage }) {
      api
        .get(`/match/${this.id}/history`, {
          params: {
            pageSize: perPage,
            pageNum: page - 1,
          },
        })
        .then((data) => {
          this.changes = data.items;
          const { pageNum, totalCount, pageSize } = data.pagen;
          this.pagination = {
            page: pageNum + 1,
            total: totalCount,
            perPage: pageSize,
          };
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.pvp-table {
  @include max-tablet() {
    margin-left: -12px;
    margin-right: -12px;
  }
}

.stripped .table-row .table-column {
  &,
  &:last-child,
  &:first-child {
    display: flex;
    @include max-laptop() {
      padding: 8px 12px;
    }
  }
}

.table {
  &-header {
    @include max-laptop() {
      display: none;
    }
  }

  &-row {
    @include min-laptop() {
      display: grid;
      grid-template-columns: 1fr 3fr;
    }
  }

  &-column {
    @include max-laptop() {
      &:nth-child(2) {
        align-items: flex-start;
      }
    }
  }
}

.lower {
  text-transform: lowercase;
}

.column {
  &-title {
    color: rgba(white, 0.6);
    @include min-laptop() {
      display: none;
    }
    @include max-laptop() {
      width: 100px;
      flex-shrink: 0;
    }
  }

  &-content {
    span {
      display: inline-block;
    }
  }
}
</style>
