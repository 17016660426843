<template>
  <div class="players">
    <span class="players-team">{{ team }}</span>
    <div
      v-for="(user, key) in players.captain"
      :key="key + 'cap'"
      class="player"
    >
      <game-profile-link
        v-if="showGameProfileLink"
        :user="user"
        :game="game"
      />
      <BaseUserLink
        :image-size="65"
        :display-role="true"
        :variant="variant"
        :hash="user.hash"
      >
        <template slot="username">{{ user.gameNick }}</template>
        <template slot="userrole">{{
          filterRole(user.role)
        }}</template>
      </BaseUserLink>
    </div>

    <div
      v-for="(user, key) in players.general"
      :key="key + 'gen'"
      class="player"
    >
      <game-profile-link
        v-if="showGameProfileLink"
        :game="game"
        :user="user"
      />
      <BaseUserLink
        :display-role="true"
        :image-size="65"
        :variant="variant"
        :hash="user.hash"
      >
        <template slot="username">{{ user.gameNick }}</template>
        <template slot="userrole">{{
          filterRole(user.role)
        }}</template>
      </BaseUserLink>
    </div>

    <div v-if="players.reserve.length" class="player-reserve">
      <div
        v-for="(user, key) in players.reserve"
        :key="key + 'res'"
        class="player"
      >
        <game-profile-link
          v-if="showGameProfileLink"
          :game="game"
          :user="user"
        />
        <BaseUserLink
          :image-size="65"
          :display-role="true"
          :variant="variant"
          :hash="user.hash"
        >
          <template slot="username">{{ user.gameNick }}</template>
          <template slot="userrole">{{
            filterRole(user.role)
          }}</template>
        </BaseUserLink>
      </div>
    </div>
  </div>
</template>

<script>
import GameProfileLink from '@components/Match/Common/GameProfileLink.vue';
import BaseUserLink from '@components/BaseComponents/BaseUserLink.vue';

export default {
  components: {
    GameProfileLink,
    BaseUserLink,
  },
  props: {
    team: {
      type: String,
      default: '',
    },
    game: {
      type: Object,
      required: true,
    },
    players: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('application', ['appIsExtraLarge']),

    variant() {
      return this.game.isHearthstone && this.appIsExtraLarge
        ? 'block'
        : '';
    },

    showGameProfileLink() {
      return this.game.linkType === 'steam';
    },
  },
  methods: {
    filterRole(id) {
      return [
        '',
        this.$t('players.role_captain'),
        '',
        this.$t('players.role_reserve'),
      ][id];
    },
  },
};
</script>

<style lang="scss" scoped>
.players {
  text-align: left;
  @include max-desktop() {
    background-color: rgba(black, 0.2);
    padding: 20px 16px;
    height: 100%;
  }

  &-team {
    font-size: 18px;
    font-weight: bold;
    color: $white;
    margin-bottom: 12px;
    display: block;
    @include min-desktop() {
      display: none;
    }
  }
}

.player {
  position: relative;

  & + & {
    margin-top: 1em;
  }

  &-reserve {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(white, 0.1);
  }
}

.user-link {
  max-width: 100%;

  ::v-deep .user-name {
    white-space: normal;
    word-wrap: break-word;
    max-width: 100%;
  }
}
</style>
