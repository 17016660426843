<template>
  <div v-if="table.isVisible" class="section">
    <span class="section-title">{{
      $t('matches.history_series_tableTitle')
    }}</span>
    <div class="pvp-table stripped">
      <div class="table-header">
        <div class="table-row">
          <div class="table-column">{{ $t('tournaments.name') }}</div>
          <div class="table-column">{{ $t('matches.single') }}</div>
          <div class="table-column">{{ $t('date.single') }}</div>
          <div class="table-column"></div>
        </div>
      </div>
      <div class="table-body">
        <div
          v-for="(row, key) in table.matches"
          :key="key"
          class="table-row"
        >
          <div class="table-column">
            <div class="column-content">
              <p class="name">{{ row.tournament.name }}</p>
              <game-tag :id="row.tournament.idGame" />
            </div>
          </div>
          <div class="table-column">
            <div class="column-title">{{ $t('matches.single') }}</div>
            <div class="column-content">
              <div class="match">
                <BaseTeamLink is-reversed :hash="row.team1.hash" />
                <div class="match-score">
                  <span
                    :class="
                      row.team1.id === row.idWinner ? 'win' : 'lose'
                    "
                    >{{ row.pointsTeam1 }}</span
                  >
                  <span class="divider">:</span>
                  <span
                    :class="
                      row.team2.id === row.idWinner ? 'win' : 'lose'
                    "
                    >{{ row.pointsTeam2 }}</span
                  >
                </div>
                <BaseTeamLink :hash="row.team2.hash" />
              </div>
            </div>
          </div>
          <div class="table-column date">
            <div class="column-title">{{ $t('date.single') }}</div>
            <div class="column-content">
              {{ $dt(row.tsStartTime, 'dd_MM_YYYY_HH_mm') }}
            </div>
          </div>
          <div class="table-column">
            <BaseLink :to="{ name: 'match', params: { id: row.id } }"
              >{{ $t('global.more') }}
              <Icon name="arrow-right" inline />
            </BaseLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';
import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';

export default {
  name: 'SeriesHistoryTable',

  components: {
    Icon,
    BaseLink,
    BaseTeamLink,
  },

  props: {
    match: {
      type: Object,
      required: true,
    },
  },

  computed: {
    table() {
      const statistics = this.match.statistics || {};

      return {
        isVisible:
          statistics.between !== undefined &&
          statistics.between.matches.length,
        ...statistics.between,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.pvp-table {
  @include max-tablet() {
    margin-left: -12px;
    margin-right: -12px;
  }
}

.stripped .table-row .table-column {
  &,
  &:last-child,
  &:first-child {
    display: flex;
    @include max-laptop() {
      padding: 8px 12px;
    }
  }
}

.table {
  &-header {
    @include max-laptop() {
      display: none;
    }
  }

  &-row {
    @include min-laptop() {
      display: grid;
      grid-template-columns: 2fr 1fr 2fr 1fr;
    }
  }

  &-column {
    max-width: 100%;
    overflow: hidden;
    @include min-laptop() {
      &:nth-child(1) {
        order: 1;
      }
      &:nth-child(2) {
        order: 3;
        justify-content: center;
      }
      &:nth-child(3) {
        order: 2;
      }
      &:nth-child(4) {
        order: 4;
        justify-content: flex-end;
      }
    }
  }
}

.column {
  &-title {
    color: rgba(white, 0.6);
    @include min-laptop() {
      display: none;
    }
    @include max-laptop() {
      width: 72px;
      flex-shrink: 0;
    }
  }

  &-content {
    flex-grow: 1;
    width: 100%;
    @include max-laptop() {
      max-width: calc(100% - 72px);
    }

    .name {
      margin-bottom: 0;
    }
  }
}

.match {
  display: flex;
  align-items: center;
  max-width: 100%;

  .team-link {
    @include min-laptop() {
      flex-grow: 2;
      flex-basis: 0;
    }
    @include max-laptop() {
      & ::v-deep {
        .icon + .team-name {
          margin-left: 0;
        }

        .team-avatar {
          display: none;
        }
      }
    }
  }

  &-score {
    font-weight: bold;
    line-height: 1;
    color: rgba(white, 0.3);
    font-size: 17px;
    text-align: center;
    margin: 0 10px;
    @include min-laptop() {
      flex-grow: 1;
      flex-basis: 0;
      flex-shrink: 0;
    }

    .divider {
      padding: 0 2px;
    }

    .win {
      color: $dark-pastel-green;
    }

    .lose {
      color: $orangey-red;
    }
  }
}
</style>
