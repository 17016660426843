<template>
  <div v-if="!isLoading" class="match">
    <box class="match-info">
      <div
        class="match-result"
        :class="{ hearthstone: game.isHearthstone }"
      >
        <div class="head">
          <p v-if="round" class="head-title">
            {{ round.name }}
            <template
              v-if="round.gameType && tournament.isPickBanSystem"
            >
              ,
              <span class="no-spaces">
                Best of {{ round.gameType }}
              </span>
            </template>
          </p>
          <p v-if="formatMatchDate" class="head-date">
            {{ formatMatchDate }}
          </p>
        </div>
        <BaseTeamLink
          :hash="teamOne.hash"
          :image-size="70"
          class="full team-one-link"
        />
        <div class="score">
          <span :class="teamOne.result">{{ teamOne.points }}</span>
          <span class="divider">:</span>
          <span :class="teamTwo.result">{{ teamTwo.points }}</span>
        </div>
        <BaseTeamLink
          :hash="teamTwo.hash"
          :image-size="70"
          class="full team-two-link"
        />
        <pvp-btn
          class="players-btn"
          variant="link"
          :icon-right="isTeamsVisible ? 'arrow-up' : 'arrow-down'"
          :class="{ open: isTeamsVisible }"
          @click="showTeamsPlayers"
        >
          {{ $t('matches.showPlayersList') }}
        </pvp-btn>
        <PlayersList
          class="players-one"
          :game="game"
          :team="teamOne.name"
          :players="teamOne.players"
        />
        <PlayersList
          class="players-two"
          :game="game"
          :team="teamTwo.name"
          :players="teamTwo.players"
        />

        <div class="info">
          <template v-if="isMatchHasDetailedScoring">
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="match.description"
              class="matchInfo"
              v-html="match.description"
            />
            <div
              v-if="roundDescription"
              class="roundInfo"
              v-html="roundDescription"
            />
            <MatchDetailsInformation
              class="match-datails-info"
              :subtitle="$t('matches.details_information_subtitle')"
              :games="match.detailedScoring"
              :a-team-key="'pointsTeam1'"
              :b-team-key="'pointsTeam2'"
            />
          </template>
          <template v-if="isMatchDigitalStageStatistics">
            <MatchDigitalStageStatistics
              v-for="(detail, idx) in detailedStats"
              :key="idx"
              class="match-digital-statistic"
              :title="detail.title"
              :statistics="detail.statistics"
              :table-data="detail.tableData"
            />
          </template>
          <template v-else>
            <div v-if="match?.teamPriority" class="match-priority">
              <i18n :path="priorityTeam?.code">
                <BaseTeamLink
                  slot="name"
                  :hash="priorityTeam?.team?.hash"
                  :display-image="false"
                />
              </i18n>
            </div>
            <pvp-btn
              v-if="canEditMatch"
              :icon-left="'pencil'"
              class="edit-match-btn"
              variant="primary"
              @click="openEditMatchPopup"
            >
              {{ $t('matches.edit_start') }}
            </pvp-btn>
            <component :is="judgeInfoVariant" :id="id" />

            <div
              v-if="match.description"
              class="matchInfo"
              v-html="match.description"
            />
            <div
              v-if="roundDescription"
              class="roundInfo"
              v-html="roundDescription"
            />
            <Checkin v-if="isCheckinVisible && !isHub" :id="id" />

            <StartGame
              v-if="hasStartButton"
              :server="match.server"
              :is-cs-game="isCsGame"
              :game-code="game.code"
            />

            <SeriesHistoryMaps :match="match" />

            <HubManualCreateGameLobby v-if="isHub && manualLobbyInfo">
              <template #header>
                <p class="manual-lobby__header">
                  {{ manualLobbyHeader }}
                </p>
              </template>
              <template #body>
                <InfoBox variant="warning" class="manual-lobby__info">
                  {{ manualLobbyInfoBox }}
                  <a
                    v-if="manualLobbyAction === 'create'"
                    :href="`/tournament/${tournament.id}/rules`"
                    target="_blank"
                  >
                    {{ $t('matches.link_to_game_rules') }}
                  </a>
                </InfoBox>
                <ClipboardInput
                  class="manual-lobby__body"
                  :label="$t('matches.lobby_name')"
                  copy-label="Скопировать"
                  :value="manualLobbyInfo.name"
                />
              </template>
              <template v-if="manualLobbyAction === 'create'" #footer>
                <Button
                  type="primary"
                  size="big"
                  :text="$t('matches.confirm_create_lobby')"
                  @click="confirmCreateLobby"
                ></Button>
              </template>
            </HubManualCreateGameLobby>

            <component
              :is="pickbanVariant"
              v-if="canPickBan"
              :id="id"
              :is-show-server-is-loading="isShowServerIsLoading"
            />

            <SeriesHistoryStats :match="match" />

            <template v-if="isMatchInfoVisible">
              <div class="result-team-container">
                <div class="team-one-result">
                  <TeamMatchResult
                    v-if="isTeamOneResultShown"
                    class="result-team-one"
                    :result="match.resultTeam[teamOne.hash]"
                  />
                </div>
                <div class="result-info"></div>
                <div class="team-two-result">
                  <TeamMatchResult
                    v-if="isTeamTwoResultShown"
                    class="result-team-two"
                    :result="match.resultTeam[teamTwo.hash]"
                  />
                </div>
              </div>
            </template>
            <Anticheat
              v-if="match.anticheat"
              :anticheat="match.anticheat"
            />
          </template>
        </div>
      </div>
    </box>
    <template v-if="!isMatchHasDetailedScoring">
      <TeamsDecsHs v-if="game.isHearthstone" :id="id" />
      <MatchStatisticTables :id="id" />
      <SeriesHistoryTable :match="match" />
      <div class="teams-matches">
        <TeamMatches :id="id" class="left" :hash="teamOne.hash" />
        <TeamMatches :id="id" class="right" :hash="teamTwo.hash" />
      </div>
      <MatchHistory
        v-if="userStatus && userStatus.roles.judge"
        :id="id"
      />
      <EditMatchPopup
        :key="editMatchPopupOpen"
        v-model="editMatchPopupOpen"
        :match-id="id"
        :rent-server="isRentServer"
        :team-one-hash="teamOne.hash"
        :team-two-hash="teamTwo.hash"
        :is-captain="isCaptain"
      />
    </template>
  </div>
</template>

<script>
import TeamMatches from '@components/Match/TeamMatches.vue';
import MatchHistory from '@components/Match/MatchHistory.vue';
import SeriesHistoryTable from '@components/Match/Common/SeriesHistoryTable.vue';
import SeriesHistoryStats from '@components/Match/Common/SeriesHistoryStats.vue';
import SeriesHistoryMaps from '@components/Match/Common/SeriesHistoryMaps.vue';
import Checkin from '@components/Match/Common/Checkin.vue';
import PlayersList from '@components/Match/Common/PlayersList.vue';
import EditMatchPopup from '@components/Match/Common/EditMatchPopup.vue';
import MatchStatisticTables from '@components/Match/Common/MatchStatisticsTables.vue';
import TeamMatchResult from '@components/Match/Common/TeamMatchResult.vue';
import Anticheat from '@components/Match/Common/Anticheat.vue';
import StartGame from '@components/Match/Common/StartGame.vue';
import PickbanShort from '@components/Match/Common/Pickban/Short.vue';
import MatchDetailsInformation from '@components/Match/Common/MatchDetailsInformation.vue';
import MatchDigitalStageStatistics from '@components/Match/Common/MatchDigitalStageStatistics.vue';
import HubManualCreateGameLobby from '@src/views/Lobby/HubManualCreateGameLobby.vue';
import Button from '@components/v2/ui/Button.vue';
import ClipboardInput from '@components/v2/ui/ClipboardInput.vue';
import BaseTeamLink from '@components/BaseComponents/BaseTeamLink.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  components: {
    InfoBox,
    StartGame,
    Checkin,
    TeamMatches,
    MatchHistory,
    SeriesHistoryTable,
    SeriesHistoryStats,
    SeriesHistoryMaps,
    PlayersList,
    MatchStatisticTables,
    EditMatchPopup,
    Anticheat,
    TeamMatchResult,
    PickbanShort,
    MatchDetailsInformation,
    MatchDigitalStageStatistics,
    HubManualCreateGameLobby,
    Button,
    ClipboardInput,
    BaseTeamLink,
    PickbanHs: () =>
      import(
        /* webpackChunkName: "MatchHs" */ '@components/Match/Common/Pickban/Heartstone.vue'
      ),
    PickbanShooters: () =>
      import(
        /* webpackChunkName: "MatchShooters" */ '@components/Match/Common/Pickban/Shooters.vue'
      ),
    JudgeHs: () =>
      import(
        /* webpackChunkName: "MatchHs" */ '@components/Match/Common/Hearthstone/JudgeInfoHs.vue'
      ),
    TeamsDecsHs: () =>
      import(
        /* webpackChunkName: "MatchHs" */ '@components/Match/Common/Hearthstone/TeamsDecsHs.vue'
      ),
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    round: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isTeamsVisible: false,
      editMatchPopupOpen: false,
      isLoading: true,
    };
  },

  computed: {
    ...mapGetters('users', ['getUserMatchStatus', 'getUser']),
    ...mapGetters('teams', ['getTeam']),
    ...mapGetters('matches', ['getMatchPickBan', 'getMatch']),
    ...mapGetters('application', ['getGameMessageById', 'getGame']),
    ...mapGetters('tournaments', ['getTournament']),
    ...mapGetters('profile', ['isLoggedIn', 'getCurrentProfileHash']),

    match() {
      return this.getMatch(this.id);
    },
    manualLobbyInfo() {
      return this.match?.ingameLobby;
    },
    manualLobbyAction() {
      return this.manualLobbyInfo?.action;
    },
    manualLobbyHeader() {
      switch (this.manualLobbyAction) {
        case 'create':
          return this.$t('matches.need_create_lobby_action');
        case 'wait':
          return `${this.userNameWhichCreateLobby} ${this.$t(
            'matches.user_will_create_lobby_action',
          )}`;
        case 'play':
          return `${this.userNameWhichCreateLobby} ${this.$t(
            'matches.user_created_lobby_action',
          )}`;
        default:
          return '';
      }
    },
    manualLobbyInfoBox() {
      switch (this.manualLobbyAction) {
        case 'create':
          return this.$t('matches.need_create_lobby_info');
        case 'wait':
          return this.$t('matches.need_wait_lobby_info');
        case 'play':
          return this.$t('matches.need_play_lobby_info');
        default:
          return '';
      }
    },
    userNameWhichCreateLobby() {
      const userHash = this.manualLobbyInfo?.responsiblePlayerHash;
      return this.getUser(userHash).name;
    },
    detailedStats() {
      return this.match?.detailedStats.map(
        ({ title, type, data }) => {
          if (type === 'nba2k' || type === 'basketball') {
            return {
              title,
              type,
              tableData: data.reduce((acc, innerData) => {
                if (innerData.side === 1) {
                  return {
                    ...acc,
                    team1Name: this.getTeam(this.match.teams[0].hash)
                      .name,
                    team1Data: innerData?.playerStats?.map(
                      ({ playerHash, points }) => ({
                        playerName: this.getUser(playerHash).name,
                        playerPoints: points,
                      }),
                    ),
                  };
                }
                if (innerData.side === 2) {
                  return {
                    ...acc,
                    team2Name: this.getTeam(this.match.teams[1].hash)
                      .name,
                    team2Data: innerData?.playerStats?.map(
                      ({ playerHash, points }) => ({
                        playerName: this.getUser(playerHash).name,
                        playerPoints: points,
                      }),
                    ),
                  };
                } else {
                  return acc;
                }
              }, {}),
            };
          } else {
            return {
              title,
              type,
              statistics: data.map((statistic) => {
                return {
                  playerTeam1:
                    Number(statistic.side) === 1
                      ? this.getUser(statistic.playerHash).name
                      : '',
                  playerTeam1ScoreTime:
                    Number(statistic.side) === 1
                      ? statistic?.time?.time
                      : '',
                  playerTeam2:
                    Number(statistic.side) === 2
                      ? this.getUser(statistic.playerHash).name
                      : '',
                  playerTeam2ScoreTime:
                    Number(statistic.side) === 2
                      ? statistic?.time?.time
                      : '',
                  commonScore: `${statistic?.score?.pointsTeam1}:${statistic?.score?.pointsTeam2}`,
                };
              }),
            };
          }
        },
      );
    },
    isMatchHasDetailedScoring() {
      return Boolean(this.match.detailedScoring);
    },
    isMatchDigitalStageStatistics() {
      return Boolean(this.match.detailedStats);
    },
    userStatus() {
      return this.getUserMatchStatus(this.id);
    },
    teamOne() {
      return this.prepareTeamData(1);
    },
    teamTwo() {
      return this.prepareTeamData(2);
    },
    userHash() {
      return this.getCurrentProfileHash;
    },
    teamOneCaptain() {
      return this.teamOne.players.captain.find(
        (item) => item.hash === this.userHash,
      );
    },
    teamTwoCaptain() {
      return this.teamTwo.players.captain.find(
        (item) => item.hash === this.userHash,
      );
    },
    currentTeamHash() {
      return this.teamOneCaptain
        ? this.teamOne.hash
        : this.teamTwo.hash;
    },

    roundDescription() {
      return _.get(this.round, 'description', '');
    },
    canPickBan() {
      return this.userStatus?.abilities?.canPickBan;
    },

    game() {
      const game = this.getGame(this.tournament.idGame);

      return {
        code: game.code,
        isHearthstone: game.code === 'hs',
        isWarface: game.code === 'wf',
        isCsGame: game.code === 'csg' || game.code === 'cs2',
        linkType: game.linkType,
      };
    },
    isCheckinVisible() {
      const { technicalDefeatHashList, hashWinner, needCheckin } =
        _.get(this, 'match', {});

      return (
        !technicalDefeatHashList.length &&
        !hashWinner &&
        _.get(this.userStatus, 'roles.participant', false) &&
        needCheckin
      );
    },

    pickbanVariant() {
      if (['wf', 'cf', 'csg', 'cs2'].includes(this.game.code)) {
        return 'PickbanShooters';
      }

      if (
        this.game.code === 'hs' &&
        this.isCaptain &&
        _.get(this.userStatus, 'abilities.canPickBan', false)
      ) {
        return 'PickbanHs';
      }

      if (this.match?.teamPriority) {
        return 'PickbanShort';
      }
      return '';
    },
    judgeInfoVariant() {
      if (this.isJudge) {
        switch (this.game.code) {
          case 'hs':
            return 'JudgeHs';
          default:
            return '';
        }
      }
      return '';
    },
    tournament() {
      return this.getTournament(this.match.idTournament);
    },

    isHub() {
      const idSystem = this.tournament.idSystem;
      return idSystem === 9;
    },

    isRentServer() {
      return this.tournament && this.tournament.rentServers;
    },

    isMatchInfoVisible() {
      return this.userStatus !== undefined;
    },
    formatMatchDate() {
      const now = new Date().getTime() / 1000;
      const start = this.match.tsStartTime;
      const end = this.match.tsEndTime;

      const held = this.$t('matches.dateStatus_held');
      const started = this.$t('matches.dateStatus_started');
      const completed = this.$t('matches.dateStatus_completed');
      const pickban = this.$t('matches.dateStatus_pickban');
      const inProgress = this.$t('matches.dateStatus_inProgress');

      let progress = '';
      let formatedDate = '';

      if (start && now < start) {
        progress = held;
        formatedDate = this.$dt(start, 'dd_MM_YYYY_HH_mm');
      } else if (end && start && now >= start && now < end) {
        progress = started;
        formatedDate = this.$dt(start, 'dd_MM_YYYY_HH_mm');
      } else if (end && now >= end) {
        progress = completed;
        formatedDate = this.$dt(end, 'dd_MM_YYYY_HH_mm');
      } else if (this.isPickBanTime) {
        progress = pickban;
      } else if (
        now >= start &&
        !end &&
        !this.isPickBanTime &&
        !this.match.checkInStartTime
      ) {
        progress = inProgress;
      }
      return `${progress} ${formatedDate}`.trim() || false;
    },

    canEditMatch() {
      return this.userStatus?.abilities?.canAddResult;
    },

    pickban() {
      return this.getMatchPickBan(this.id);
    },

    isCsGame() {
      return this.game.isCsGame;
    },

    isCaptain() {
      return _.get(this.userStatus, 'roles.captain', false);
    },
    isJudge() {
      return _.get(this.userStatus, 'roles.judge', false);
    },
    isParticipant() {
      return _.get(this.userStatus, 'roles.participant', false);
    },
    isShowServerIsLoading() {
      const statusFinishedPickban = 3;
      const isFinishedPickban =
        this.pickban?.status === statusFinishedPickban;
      return (
        !this.match?.hashWinner &&
        (this.isParticipant || this.isJudge) &&
        isFinishedPickban &&
        !this.userStatus.abilities.canConnectToServer &&
        this.isCsGame
      );
    },
    isTeamOneResultShown() {
      return (
        !!this.match.resultTeam &&
        !!this.match.resultTeam[this.teamOne.hash] &&
        this.teamOneCaptain
      );
    },
    isTeamTwoResultShown() {
      return (
        !!this.match.resultTeam &&
        !!this.match.resultTeam[this.teamTwo.hash] &&
        this.teamTwoCaptain
      );
    },
    hasStartButton() {
      return (
        this.userStatus?.abilities?.canConnectToServer &&
        _.isObject(this.match?.server)
      );
    },
    priorityTeam() {
      return {
        team: this.match.teams.find(
          (team) => team.side === this.match?.teamPriority,
        ),
        code: this.getGameMessageById(
          this.tournament?.gameMessage?.id,
        ),
      };
    },
    isPickBanTime() {
      const now = new Date().getTime() / 1000;
      return (
        !!this.getMatchPickBan(this.id) &&
        this.getMatchPickBan(this.id).pickBanStartTime < now &&
        this.getMatchPickBan(this.id).expired > now
      );
    },
  },
  async created() {
    await this.fetchMatchStatistic(this.id);

    if (this.isLoggedIn) {
      await this.showMatchTeamResult({
        id: this.id,
        teamHash: this.currentTeamHash,
      });
    }

    this.isLoading = false;
  },
  methods: {
    ...mapActions('matches', [
      'fetchMatchStatistic',
      'showMatchTeamResult',
    ]),

    prepareTeamData(side) {
      const teamData = this.match.teams.find(
        (team) => team.side === side,
      );

      if (!teamData) {
        return {};
      }

      const team = this.getTeam(teamData.hash);

      if (!team) {
        return {};
      }

      const players = team.players.reduce(
        (type, player) => {
          switch (player.role) {
            case 1:
              type.captain.push(player);
              break;
            case 3:
              type.reserve.push(player);
              break;
            default:
              type.general.push(player);
          }
          return type;
        },
        {
          captain: [],
          general: [],
          reserve: [],
        },
      );

      let result = '';
      let points = '';
      if (this.match.technicalDefeatHashList.length) {
        const isLoser = this.match.technicalDefeatHashList.includes(
          teamData.hash,
        );
        result = isLoser ? 'lose' : 'win';
        points = isLoser ? this.$t('matches.tech_shortDefeat') : '1';
      } else {
        result = ((hashWinner) => {
          if (!hashWinner) {
            return '';
          }
          return team.hash === hashWinner ? 'win' : 'lose';
        })(this.match.hashWinner);
        points = this.match[`pointsTeam${team.side}`];
      }

      return {
        ...team,
        result,
        points,
        players,
      };
    },

    showTeamsPlayers() {
      this.isTeamsVisible = !this.isTeamsVisible;
    },

    openEditMatchPopup() {
      this.editMatchPopupOpen = true;
    },

    async confirmCreateLobby() {
      const url = `match/${this.id}/lobby`;
      await api.post(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.match {
  &-info {
    margin-top: 30px;
    @include min-laptop() {
      padding: 40px 30px;
    }
    @include max-laptop() {
      padding: 20px 16px;
    }
    @include max-tablet() {
      margin-left: -12px;
      margin-right: -12px;
    }
  }

  .players {
    @include min-desktop() {
      overflow: hidden;
    }
    @include max-desktop() {
      display: none;
    }

    &-one {
      grid-area: playersOne;
    }

    &-two {
      grid-area: playersTwo;
    }

    &-btn {
      margin: 0 auto;
      grid-area: playersBtn;
      width: 100%;
      @include min-desktop() {
        display: none;
      }

      &.open {
        margin-bottom: 12px;

        & ~ .players {
          width: 100%;
          display: block;

          &.players-one {
            border-radius: 4px 4px 0 0;
          }

          &.players-two {
            border-radius: 0 0 4px 4px;
          }
        }
      }
    }
  }

  &-result {
    text-align: center;
    flex-grow: 1;
    @include min-laptop() {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      &.hearthstone {
        grid-template-columns: 1fr 274px 230px 274px 1fr;
      }
      /*@formatter:off*/
      grid-template-areas:
        'playersOne teamOne result teamTwo playersTwo'
        'playersOne teamOne score  teamTwo playersTwo'
        'playersOne info    info   info    playersTwo'
        'playersOne .       .      .       playersTwo';
      /*@formatter:on*/
    }
    @include max-desktop() {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .team {
      &-one {
        &-link {
          grid-area: teamOne;
        }
      }

      &-two {
        &-link {
          grid-area: teamTwo;
        }
      }
    }
  }
}

.head {
  flex-grow: 1;
  text-align: center;
  grid-area: result;
  @include max-desktop() {
    width: 100%;
  }

  &-title {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin: 0;
    line-height: 1;
  }

  &-date {
    font-size: 14px;
    color: rgba(white, 0.4);
    margin: 14px 0 0;
    line-height: 1;
  }
}

.info {
  margin-top: 20px;
  grid-area: info;
  width: 100%;
  @include min-desktop() {
    padding: 0 30px;
  }
  @include max-laptop() {
    margin-left: auto;
    margin-right: auto;
  }
}

.match-priority {
  margin-bottom: 20px;
}

.result-team-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.score {
  font-weight: bold;
  line-height: 1;
  grid-area: score;
  color: rgba(white, 0.3);
  font-size: 36px;
  @include min-tablet() {
    font-size: 77px;
    margin-top: 20px;
  }
  @include max-desktop() {
    align-self: center;
    width: 33%;
  }

  .win {
    color: $dark-pastel-green;
  }

  .lose {
    color: $orangey-red;
  }
}

.team-link.full ::v-deep {
  .team-icon {
    @include min-tablet() {
      width: 120px !important;
      height: 120px !important;
    }
  }
  @include max-desktop() {
    width: 33%;
    flex-shrink: 0;
    margin-top: 20px;
    .team {
      &-name {
        margin-top: 12px;
        font-size: 16px;
      }
    }
  }
}

.teams-matches {
  @include min-desktop() {
    display: grid;
    grid-template-areas: 'left right';
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    .left {
      grid-area: left;
    }

    .right {
      grid-area: right;
    }
  }
}

.edit-match-btn {
  margin-bottom: 16px;
}

.roundInfo,
.matchInfo {
  text-align: left;
  padding: 20px 10px;
  margin-bottom: 1px;
  background: rgba(black, 0.2);
  overflow: auto;

  ::v-deep p {
    margin: 0;
  }
}

.no-spaces {
  white-space: nowrap;
}

.match-datails-info,
.match-digital-statistic {
  margin-bottom: 16px;
}

.manual-lobby__info,
.manual-lobby__body {
  margin-bottom: 12px;
}

.manual-lobby__header {
  color: #f5f5f7;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 12px;
}
</style>
