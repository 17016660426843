<template>
  <div class="match-digital-stage-statistics">
    <h2 class="match-digital-stage-statistics__title">{{ title }}</h2>
    <div v-if="Object.keys(tableData).length" class="table-wrapper">
      <div class="table-left">
        <table class="match-table">
          <tr>
            <td
              class="
                match-table__item
                match-table--team-name
                match-table--align-left
              "
            >
              {{ tableData.team1Name }}
            </td>
            <td
              class="
                match-table__item
                match-table--points
                match-table--align-left
              "
            >
              {{ $t('rating.points') }}
            </td>
          </tr>
          <tr v-for="(data, idx) in tableData.team1Data" :key="idx">
            <td class="match-table__item match-table--align-left">
              {{ data.playerName }}
            </td>
            <td class="match-table__item match-table--align-left">
              {{ data.playerPoints }}
            </td>
          </tr>
        </table>
      </div>
      <div>
        <table class="match-table">
          <tr>
            <td
              class="
                match-table__item
                match-table--points
                match-table--align-right
              "
            >
              {{ $t('rating.points') }}
            </td>
            <td
              class="
                match-table__item
                match-table--team-name
                match-table--align-right
              "
            >
              {{ tableData.team2Name }}
            </td>
          </tr>
          <tr v-for="(data, idx) in tableData.team2Data" :key="idx">
            <td class="match-table__item match-table--align-right">
              {{ data.playerPoints }}
            </td>
            <td class="match-table__item match-table--align-right">
              {{ data.playerName }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <ul v-else class="match-digital-stage-statistics__list">
      <li
        v-for="(statistic, idx) in statistics"
        :key="idx"
        class="match-digital-stage-statistics__item"
      >
        <p
          class="
            match-digital-stage-statistics__player
            match-digital-stage-statistics--player1-align-right
          "
        >
          {{ statistic.playerTeam1 }}
          <span class="match-digital-stage-statistics__score-time">
            {{ statistic.playerTeam1ScoreTime }}
          </span>
        </p>
        <p class="match-digital-stage-statistics__score">
          {{ statistic.commonScore }}
        </p>
        <p
          class="
            match-digital-stage-statistics__player
            match-digital-stage-statistics--player2-align-left
          "
        >
          <span class="match-digital-stage-statistics__score-time">
            {{ statistic.playerTeam2ScoreTime }}
          </span>
          {{ statistic.playerTeam2 }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MatchDigitalStageStatistics',
  props: {
    title: {
      type: String,
      default: '',
    },
    tableData: {
      type: Object,
      default: () => ({}),
    },
    statistics: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.match-digital-stage-statistics {
  padding: 20px 8px;
  background: #232632;

  @include min-desktop() {
    padding: 28px;
  }

  &--player1-align-right {
    text-align: right;
  }
  &--player2-align-left {
    text-align: left;
  }
}
.match-digital-stage-statistics__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #f5f5f7;
  text-align: center;
  margin-bottom: 20px;
}
.match-digital-stage-statistics__item {
  list-style: none;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #d8d8d8;
  display: grid;
  grid-template-columns: 1fr 40px 1fr;
  margin-bottom: 15px;
  @include min-tablet() {
    grid-template-columns: 1fr 100px 1fr;
  }
}
.match-digital-stage-statistics__score {
  text-align: center;
}
.match-digital-stage-statistics__score-time {
  color: #979797;
  font-weight: 400;
}
.table-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-left {
  @include min-tablet() {
    margin-right: 35px;
  }
}

.match-table__item {
  color: #d8d8d8;
  font-size: 12px;
  line-height: 1.4;
  padding-bottom: 14px;
  padding-right: 12px;
}

.match-table--team-name {
  font-weight: 700;
}

.match-table--points {
  font-weight: 700;
  color: #979797;
}

.match-table--align-left {
  text-align: left;
}

.match-table--align-right {
  text-align: right;
}
</style>
