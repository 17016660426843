<style lang="scss" scoped>
.team-result-container {
  width: 274px;
  padding: 16px 22px;
  border-radius: 6px;
  background-color: rgba($leafy-green, 0.17);
  box-sizing: border-box;
  @include max-tablet() {
    width: 142px;
    padding: 12px;
  }
}

.team-result-title {
  font-size: 14px;
  text-align: center;
  line-height: normal;
}

.team-result-icon {
  margin-right: 8px;
  color: $dark-pastel-green;
  @include max-tablet() {
    display: none;
  }
}

.team-result-scores {
  margin-top: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include max-tablet() {
    margin-top: 4px;
  }
}

.team-result-score {
  font-size: 17px;
  font-weight: bold;
  color: $dark-pastel-green;

  &.lose {
    color: $orangey-red;
  }
}

.dots-delimiter {
  margin: 0 3px;
  color: rgba(#fff, 0.4);
}

.team-result-screenshots {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  @include max-tablet() {
    flex-direction: column;
    align-items: center;
  }
}

.team-result-screenshot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 40px;
  margin-right: 6px;
  margin-top: 6px;
  cursor: pointer;

  &:nth-child(3n) {
    margin-right: 0;
  }

  .team-result-img {
    max-width: 72px;
    max-height: 40px;
  }
}
</style>

<template>
  <div class="team-result-container">
    <div class="team-result-title">
      <icon name="check-circle" class="team-result-icon" />
      {{ $t('matches.edit_score') }}:
    </div>
    <div class="team-result-scores">
      <div
        class="team-result-score"
        :class="{ lose: result.pointsTeam1 < result.pointsTeam2 }"
      >
        {{ result.pointsTeam1 }}
      </div>
      <div class="dots-delimiter">:</div>
      <div
        class="team-result-score"
        :class="{ lose: result.pointsTeam1 > result.pointsTeam2 }"
      >
        {{ result.pointsTeam2 }}
      </div>
    </div>
    <div class="team-result-screenshots">
      <a
        v-for="(img, index) in result.imgScreens"
        :key="index"
        class="team-result-screenshot"
        :href="img"
        target="_blank"
      >
        <img class="team-result-img" :src="img" />
      </a>
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'TeamMatchResult',
  components: {
    Icon,
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
};
</script>
